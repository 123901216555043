import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS, Dialog, Paginate } from 'sw-ui';

import LinkToNewTab from '../../../../components/LinkToNewTab';
import MaterialIcon from '../../../../components/materialIcon';
import DecisionButtons from '../../../../components/DecisionButtons';
import EditTransactionDialog from './dialogs/editTransaction';

import { formatDate, momentObject } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';
import scrollToTop from '../../../../utils/scrollToTop';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import ITEMSONPAGE from '../../../../constants/pagination';
import { FULLTIME } from '../../../../constants/time';
import { TRIPBUTTONS } from '../../../../bi/constants/trips';

import styles from './styles/table.module.css';

const COMPONENTNAME = 'tableState';
const EDITTRANSACTIONCOMPONENTNAME = 'editTransactionDialogState';

const LABELS = {
  DATE: 'Дата',
  OPERATION: 'Операция',
  ORDER_NUMBER: 'Номер заказа',
  ENTRANCE: 'Поступление',
  WRITE_OFF: 'Списание',
  DELETE: 'Вы уверены, что хотите удалить операцию ?',
  DELETE_PLAN_FEE: 'Операция списания абонентской платы будет удалена. Вы уверены?',
  DELETE_PLAN_FEE_WITH_CLOSE_DOCUMENT: 'Вы уверены? Данная операция уже была учтена в закрывающих документах.',
  ARE_NO_DOCUMENTS: 'Документов за выбранный период нет',
  ERROR: 'Проблема с сервером! Не удалось выполнить удаление.',
  ERROR_MSG: 'У вас нет прав на редактирование документов в закрытом периоде',
};

class Table extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    updateFinance: PropTypes.func.isRequired,
    transactions: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    validation: PropTypes.object.isRequired,
    editTransactionDialogState: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    waitigResponseForApprove: PropTypes.bool.isRequired,
    errorFlag: PropTypes.bool,
    filterTransactions: PropTypes.array.isRequired,
    IsPlanFee: PropTypes.bool.isRequired,
    IsBankPayment: PropTypes.bool.isRequired,
    IsCardPaymentOrTransactionFee: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    errorFlag: false,
  }

  constructor() {
    super();

    this.state = {
      show: false,
      flagMsgError: false,
      openApproveDialog: false,
      planFeeInCloseDocument: false,
      waitingResponseForEdit: false,
    };
  }

  handleConfirmDelete = async () => {
    const { companyService, updateFinance, state: { transaction: { Id } } } = this.props;

    await companyService.deleteTransaction(Id);
    updateFinance();
  }

  handleConfirmDeletePlanFee = async () => {
    const { companyService, updateFinance, state: { transaction: { Id } } } = this.props;

    await companyService.deletePlanFeeTransaction(Id);
    updateFinance();
  }

  handleOpenErrorTransaction = () => this.setState({ flagMsgError: true, show: false });

  handleOpenApproveDialog = () => this.setState({ ...this.state, openApproveDialog: true });

  handleCloseApproveDialog = () => this.setState({ ...this.state, openApproveDialog: false, waitingResponseForEdit: false });

  handelBeforeOpenDialog = () => this.setState({
    ...this.state,
    approveFunc: this.handleConfirmDelete,
    approveText: LABELS.DELETE,
  }, this.handleOpenApproveDialog);

  handleSaveTransaction = (date, amount, description) => {
    const { companyService, updateFinance, state: { transaction: { Id, Filter: { IsPlanFee } } } } = this.props;
    const data = {
      Amount: amount,
      CreatedDate: formatDate(date, FULLTIME),
      Description: description,
    };

    if (IsPlanFee) {
      return companyService.savePlanFeeTransaction(Id, amount)
        .then(this.toggleEditTransactionDialog)
        .then(updateFinance);
    }

    return companyService.saveTransaction(Id, data)
      .then(this.toggleEditTransactionDialog)
      .then(updateFinance);
  };

  handelCheckClosingDocumentsForDeletion = async () => {
    const { companyService, state: { transaction: { Id } } } = this.props;

    const res = await companyService.finance.checkOperationInClosingDocuments(Id);

    const approveText = res
      ? LABELS.DELETE_PLAN_FEE_WITH_CLOSE_DOCUMENT
      : LABELS.DELETE_PLAN_FEE;

    this.setState({
      ...this.state,
      approveFunc: this.handleConfirmDeletePlanFee,
      approveText,
    }, this.handleOpenApproveDialog);
  }

  handelCheckClosingDocumentsForChange = async (date, amount, description, waitingValue = false) => {
    const { companyService, state: { transaction: { Id } } } = this.props;

    const res = await companyService.finance.checkOperationInClosingDocuments(Id);

    if (res) {
      return this.setState({
        ...this.state,
        waitingResponseForEdit: waitingValue,
        approveFunc: () => this.handleSaveTransaction(date, amount, description),
        approveText: LABELS.DELETE_PLAN_FEE_WITH_CLOSE_DOCUMENT,
      }, this.handleOpenApproveDialog);
    }

    return this.handleSaveTransaction(date, amount, description);
  }

  toggleEditTransactionDialog = (transaction) => {
    const {
       companyService: {
         finance: {
           setComponentState,
           },
        },
    } = this.props;

    if (transaction) {
      setComponentState(EDITTRANSACTIONCOMPONENTNAME, 'amount', transaction.Debit);
      setComponentState(EDITTRANSACTIONCOMPONENTNAME, 'credit', transaction.Credit);
      setComponentState(COMPONENTNAME, 'transaction', transaction);
      setComponentState(EDITTRANSACTIONCOMPONENTNAME, 'date', momentObject(transaction.EventDate));
      setComponentState(EDITTRANSACTIONCOMPONENTNAME, 'description', transaction.Description);
      setComponentState(EDITTRANSACTIONCOMPONENTNAME, 'type', transaction.Type);
    }

    this.setState({ ...this.state, show: Boolean(transaction) });
  };

  handlePageChange = (event, page) => this.props.companyService.finance.changePage(page);

  handleSetItemsOnPage = (event, amt) => {
    event.preventDefault();
    this.props.companyService.finance.setItemsOnPage(amt);
  };

  renderEmpty = () => (
    <div className={ `empty-list ${styles.empty}` }>
      { LABELS.ARE_NO_DOCUMENTS }
    </div>
  );

  renderIcon = item => (
    <MaterialIcon
      icon='edit'
      onClick={ () => this.toggleEditTransactionDialog(item) }
      className={ styles.edit }
    />
    )

  renderTableHeader = () => (
    <div ref={ (elem) => { this.tableHeader = elem; } } className={ styles['table-header'] }>
      <div className={ styles['col-1-8'] }>{LABELS.DATE}</div>
      <div className={ styles['col-4-8'] }>{LABELS.OPERATION}</div>
      <div className={ styles['col-1-8'] }>{LABELS.ORDER_NUMBER}</div>
      <div className={ styles['col-1-8'] }>{LABELS.ENTRANCE}</div>
      <div className={ styles['col-1-8'] }>{LABELS.WRITE_OFF}</div>
    </div>
  );

  renderTableBody = (visibleTransactions) => {
    const { featureFlagsService: { getPlanFeeChange } } = this.props;

    const list = () => visibleTransactions.map((item) => {
      const { Debit, Credit, EventDate, Id, Description, Filter: { IsPlanFee }, TripId, CompanyId } = item;

      const tripIdRule = TripId === 0 ? null : TripId;

      const descriptionElem = () => {
        if (parseFloat(Debit) || (IsPlanFee && getPlanFeeChange())) {
          return (
            <div className={ `${styles['col-4-8']} ${styles.editable}` }>
              { Description }
              { this.renderIcon(item) }
            </div>
          );
        }

        return (
          <div className={ styles['col-4-8'] }>
            { Description }
          </div>
        );
      };

      return (
        <div key={ Id } className={ styles['table-row'] }>
          <div className={ styles['col-1-8'] }>{ formatDate(EventDate) }</div>
          { descriptionElem() }
          <div className={ styles['col-1-8'] }>
            <LinkToNewTab to={ `/account/${this.props.accountId}/company/${CompanyId}/trip/${TripId}` }>
              <span className={ styles.employee }>
                { tripIdRule }
              </span>
            </LinkToNewTab>
          </div>
          <div className={ styles['col-1-8'] }>
            { Debit ? MoneyFormat.symbolWithMoneyWithDecimal(Debit) : '' }
          </div>
          <div className={ styles['col-1-8'] }>
            { Credit ? MoneyFormat.symbolWithMoneyWithDecimal(Credit) : '' }
          </div>
        </div>
      );
    });

    return (
      <div className={ styles['table-body'] }>
        {list()}
      </div>
    );
  };

  renderItemsOnPageElem = (itemsOnPage, opt) => (
    <div
      className={ `${styles['amt-opt']} ${itemsOnPage === opt ? styles.active : ''}` }
      onClick={ event => this.handleSetItemsOnPage(event, opt) }
    >
      { opt }
    </div>
  );

  renderPagination = ({ OPT2, OPT3, OPT4 }, currentPage, itemsOnPage, totalItems) => (
    <div className={ styles.pagination }>
      { totalItems < OPT2 ? null : (
        <div className={ styles['to-top'] } onClick={ () => scrollToTop(this.tableHeader) }>
          <MaterialIcon icon='arrow_upward' />
          <span className={ styles['to-top-text'] }>&nbsp;к началу страницы</span>
        </div>
      ) }
      <Paginate
        handlePrevious={ this.handlePageChange }
        handlePage={ this.handlePageChange }
        handleNext={ this.handlePageChange }
        currentPage={ currentPage }
        itemsOnPage={ itemsOnPage }
        totalItems={ totalItems }
      />
      { totalItems < OPT2 ? null : (
        <div className={ styles['amt-opts'] }>
          { this.renderItemsOnPageElem(itemsOnPage, OPT2) }
          { this.renderItemsOnPageElem(itemsOnPage, OPT3) }
          { this.renderItemsOnPageElem(itemsOnPage, OPT4) }
        </div>
      ) }
    </div>
  );

  renderApproveDialog = (openApproveDialog) => {
    const { approveFunc, approveText } = this.state;
    const { waitigResponseForApprove } = this.props;

    if (!openApproveDialog) return null;

    return (
      <Dialog onClick={ this.handleCloseApproveDialog } >
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <span>{approveText}</span>
          <div className={ `${styles.row} ${styles.action}` }>
            <DecisionButtons
              disabled={ waitigResponseForApprove }
              onSave={ approveFunc }
              onCancel={ this.handleCloseApproveDialog }
              labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
            />
          </div>
        </div>
      </Dialog>
    );
  }

  renderErrorDelete = (errorFlag, companyService) => (errorFlag ? (
    <Dialog onClick={ () => companyService.finance.flagDeleteTransaction() } >
      <div className={ `${styles['form-dialog']}` }>
        <span>{LABELS.ERROR}</span>
      </div>
    </Dialog>
  ) : null);


  render() {
    const {
      companyService,
      pagination: {
        visibleTransactions,
        currentPage,
        itemsOnPage,
      },
      state: {
        transaction,
      },
      transactions,
      validation,
      editTransactionDialogState,
      errorFlag,
      featureFlagsService: {
        getIncomingOperationChange,
      },
      filterTransactions,
      IsPlanFee,
      IsBankPayment,
      IsCardPaymentOrTransactionFee,
    } = this.props;

    const {
      openApproveDialog,
      show,
      waitingResponseForEdit,
      flagMsgError,
    } = this.state;

    const { DIALOG: { MIDDLE }, BUTTON: { THEME: { FLAT } } } = COMPONENTS;

    const selectTransaction = IsPlanFee || IsBankPayment || IsCardPaymentOrTransactionFee
      ? filterTransactions
      : transactions;

    const incomingOperationChange = getIncomingOperationChange();

    const editTransactionDialogElem = show ? (
      <div>
        <EditTransactionDialog
          companyService={ companyService }
          transaction={ transaction }
          validation={ validation.editTransactionDialog }
          state={ editTransactionDialogState }
          onSaveTransaction={ this.handelCheckClosingDocumentsForChange }
          onClose={ () => this.toggleEditTransactionDialog() }
          onOpenApproveDialog={ this.handelBeforeOpenDialog }
          onCheckClosingDocumentsForDeletion={ this.handelCheckClosingDocumentsForDeletion }
          incomingOperationChange={ incomingOperationChange }
          waitingResponse={ waitingResponseForEdit }
          onOpenErrorTransaction={ this.handleOpenErrorTransaction }
        />
      </div>
    ) : null;

    const msgErrorRuls = flagMsgError ? (
      <div>
        <Dialog
          show={ flagMsgError }
          onClick={ () => this.setState({ flagMsgError: false }) }
          width={ MIDDLE }
        >
          <div className={ styles.dialog }>
            <p>{ LABELS.ERROR_MSG }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button
                label={ TRIPBUTTONS.CLOSE }
                theme={ FLAT }
                onClick={ () => this.setState({ flagMsgError: false }) }
              />
            </div>
          </div>
        </Dialog>
      </div>
    ) : null;

    return selectTransaction.length ? (
      <div>
        { this.renderTableHeader() }
        { this.renderTableBody(visibleTransactions) }
        { this.renderPagination(ITEMSONPAGE, currentPage, itemsOnPage, selectTransaction.length) }
        { editTransactionDialogElem }
        { msgErrorRuls }
        { this.renderApproveDialog(openApproveDialog) }
        { this.renderErrorDelete(errorFlag, companyService) }
      </div>
    ) : this.renderEmpty();
  }
}

export default Table;
