import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';

import Button from '../../../../../../components/button/Button';
import FlatButton from '../../../../../../components/button/flatButton';

import { formatDate } from '../../../../../../bi/utils/formatDate';

import {
  LABELS,
  HEADER_LABELS,
  VAT_LABELS,
  REQUEST_STATUS_LABELS,
  INTERCOM_LINK,
  BUTTON_LABELS,
} from '../../../../../../bi/constants/hotelBookRequest';
import { DAYMONTHPATTERN } from '../../../../../../constants/time';

import styles from './index.module.css';

const RequestList = ({
  savedRequests,
  customers,
  isLoading,
  onDelete,
  loadRequests,
  companyId,
  accountId,
  changeIsEditing,
  onEdit,
}) => {
  const [renderedRequests, setRenderedRequests] = useState([]);

  useEffect(() => {
    loadRequests(companyId);
  }, [companyId, loadRequests]);

  useEffect(() => {
    setRenderedRequests(savedRequests.slice(0, 5));
  }, [savedRequests]);

  const handleLoadMore = () => {
    setRenderedRequests(savedRequests);
  };

  const handleHideShowed = () => {
    setRenderedRequests(savedRequests.slice(0, 5));
  };

  const formatNames = (customer) => {
    if (!customer || !customer.label) {
      return null;
    }

    const [surname, name, patronymic] = customer.label.split(' ');

    return `${surname} ${name.charAt(0)}. ${patronymic ? `${patronymic.charAt(0)}.` : ''}`;
  };

  const getCustomerNames = employees => employees.reduce((acc, employee) => {
    const customer = customers.find(item => item.value === employee);

    if (customer) {
      const formattedName = formatNames(customer);
      return [
        ...acc,
        <div
          key={ customer.value }
          className={ styles.customer }
        >
          {formattedName}
        </div>,
      ];
    }

    return acc;
  }, []);

  const getVatType = (worksWithVat, priceInclVat) => {
    if (!worksWithVat && !priceInclVat) {
      return <div>{VAT_LABELS.withoutVat}</div>;
    }

    if (worksWithVat && priceInclVat) {
      return <div>{VAT_LABELS.vatIncluded}</div>;
    }

    if (worksWithVat && !priceInclVat) {
      return <div>{VAT_LABELS.worksWithVat}</div>;
    }
  };

  const setRequestStatus = (status) => {
    let statusColor;

    switch (status) {
      case REQUEST_STATUS_LABELS.Incomplete:
        statusColor = styles.status_incomplete;
        break;
      case REQUEST_STATUS_LABELS.Pending:
        statusColor = styles.status_pending;
        break;
      case REQUEST_STATUS_LABELS.Confirmed:
        statusColor = styles.status_confirmed;
        break;
      case REQUEST_STATUS_LABELS.TripAdded:
        statusColor = styles.status_tripAdded;
        break;
      default:
        statusColor = styles.status_incomplete;
    }

    return <div className={ statusColor }>{status}</div>;
  };

  const handleDeleteRequest = async (requestId) => {
    await onDelete(requestId);
    await loadRequests(companyId);
  };

  const handleEditRequest = async (requestId) => {
    await changeIsEditing(true);
    await onEdit(requestId, accountId);
  };

  const renderSavedRequests = () => renderedRequests.map(({
    id,
    conversationId,
    hotelName,
    checkinTime,
    checkoutTime,
    status,
    employees,
    worksWithVat,
    priceInclVat,
    },
    index
  ) => {
    const CHAT_LINK = conversationId.toString().length <= 14 ? INTERCOM_LINK.PROD : INTERCOM_LINK.TEST;

    return (
      <div className={ styles.rows_wrap } key={ index }>
        <div className={ `${styles.row} ${styles.item_highlight}` }>
          <div className={ styles.list_item }>
            <a
              href={ CHAT_LINK(conversationId) }
              className={ styles.intercomLink }
              target={ '_blank' }
              rel='noreferrer'
            >
              {conversationId}
            </a>
          </div>
          <div className={ styles.row } onClick={ () => handleEditRequest(id) }>
            <div className={ styles.list_item }>{hotelName}</div>
            <div className={ styles.list_item }>
              {getVatType(worksWithVat, priceInclVat)}
            </div>
            <div className={ styles.list_item }>{getCustomerNames(employees)}</div>
            <div className={ styles.list_item }>{formatDate(checkinTime, DAYMONTHPATTERN)}</div>
            <div className={ styles.list_item }>{formatDate(checkoutTime, DAYMONTHPATTERN)}</div>
            <div className={ styles.list_item }>{setRequestStatus(status)}</div>
          </div>
        </div>
        <div>
          <FlatButton
            onClick={ () => handleDeleteRequest(id) }
          >
            <i className='material-icons'>delete</i>
          </FlatButton>
        </div>
      </div>
    );
  });

  const renderHeaderLabels = (headerLabels) => {
    const headerElements = Object.keys(headerLabels).map(key => (
      <div className={ styles.header_label } key={ key }>
        <label>{headerLabels[key]}</label>
      </div>
    ));

    return (
      <div className={ styles.row }>
        {headerElements}
      </div>
    );
  };

  const renderNoRequests = () => (
    <div className={ styles.noRequests }>
      {LABELS.NO_SAVED_REQUESTS}
    </div>
  );

  const renderLoadMoreButton = () => {
    if (renderedRequests.length < 5) {
      return null;
    }

    const label = renderedRequests.length > 5 ? BUTTON_LABELS.HIDE : BUTTON_LABELS.LOAD_MORE;
    const handler = renderedRequests.length > 5 ? handleHideShowed : handleLoadMore;

    return (
      <Button
        label={ label }
        className={ styles.button_wrap }
        onClick={ handler }
      />
    );
  };

  if (isLoading) {
    return (
      <div className={ styles.loading }>
        <Loading size='large' />
      </div>
    );
  }

  if (!savedRequests || !savedRequests.length) {
    return renderNoRequests();
  }

  return (
    <div className={ styles.requestsWrap }>
      <div className={ styles.header }>{LABELS.SAVED_REQUESTS_HEADER}</div>
      <div>{renderHeaderLabels(HEADER_LABELS)}</div>
      <div className={ styles.requestsList }>{renderSavedRequests()}</div>
      {renderLoadMoreButton()}
    </div>
  );
};

RequestList.propTypes = {
  savedRequests: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  loadRequests: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  changeIsEditing: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default RequestList;
