import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Loading } from 'sw-ui';

import RangeDatePicker from '../../../../components/RangeDatePicker';
import PlanFee from '../trips/dialogs/planFee';
import Table from './table';
import DebitOrCreditDialog from './dialogs/debitOrCredit';

import {
  getCurrentDate,
  getStartDateOfLastMonth,
  getSmartwayBirthDate,
  formatDate,
} from '../../../../utils/date';

import { ACCOUNTTEXT, RATE_LIST } from '../../../../bi/constants/account';
import { FULLTIME } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';

import styles from './styles/index.module.css';

const SINCELASTMONTH = 'sinceLastMonth';
const ALLTIME = 'allTime';
const CREDIT = 'credit';
const CREDITBYCARD = 'creditByCard';
const DEBIT = 'debit';

const LABELS = {
  ONLY_PLAN_FEE: 'Только списания абонентской платы',
  ONLY_BANK_PAYMENT: 'Только банковские пополнения',
  ONLY_CARD_PAYMENT_OR_TRANSACTION_FEE: 'Только пополнения картой и комиссия за транзакцию',
  ADD_PLUS: 'ДОБАВИТЬ ПОСТУПЛЕНИЕ',
  ADD_MINUS: 'ДОБАВИТЬ СПИСАНИЕ',
  PAY_CARD: 'ОПЛАТА КАРТОЙ',
  ADD_DEDUCTION_AB: 'Добавить списание за справки по АБ',
  ACCOUNT_TRSANSACTIONS: 'Операции по счету',
  FROM_PREVIOUS_MONTH: 'c предыдущeго месяца',
  ALL_TIME: 'за все время',
  DISPLAY_TRANSACTIONS: 'Отображать операции',
  SELECT_AMOUNT: 'выберите сумму',
};

const FILTERS = {
  IS_PLAN_FEE: 'IsPlanFee',
  IS_BANK_PAYMENT: 'IsBankPayment',
  IS_CARD_PAYMENT_OR_TRANSACTION_FEE: 'IsCardPaymentOrTransactionFee',
};

class Finance extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    accountService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const {
      startDate,
      endDate,
      maxDate,
      finance: {
        IsPlanFee,
        IsBankPayment,
        IsCardPaymentOrTransactionFee,
        filterTransactions,
        transactions,
        pagination,
        validation,
        errorFlag,
        tableState,
        debitOrCreditDialogState,
        editTransactionDialogState,
      },
      waitigResponseForApprove,
    } = props.companyService.get();

    this.state = {
      startDate,
      endDate,
      maxDate,
      transactions,
      pagination,
      errorFlag,
      validation,
      tableState,
      debitOrCreditDialogState,
      editTransactionDialogState,
      waitigResponseForApprove,
      debitOrCreditDialog: {
        show: false,
        field: '',
      },
      waitingResponse: {
        transactions: true,
      },
      IsPlanFee,
      IsBankPayment,
      IsCardPaymentOrTransactionFee,
      filterTransactions,
      showDeductionAviaDialog: false,
    };
  }

  componentDidMount() {
    const { companyService } = this.props;
    const { startDate, endDate } = this.state;

    this.unsubscribe = companyService.subscribe(this.update);

    companyService.finance.loadFinance(startDate, endDate);
  }

  componentWillUnmount() {
    this.props.companyService.finance.reset();
    this.unsubscribe();
  }

  update = ({
    finance: {
      transactions,
      pagination,
      errorFlag,
      validation,
      tableState,
      debitOrCreditDialogState,
      editTransactionDialogState,
      waitigResponseForApprove,
      IsPlanFee,
      IsBankPayment,
      IsCardPaymentOrTransactionFee,
      filterTransactions,
    },
  }) => {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.waitingResponse.transactions = false;
      newState.transactions = transactions;
      newState.errorFlag = errorFlag;
      newState.pagination = pagination;
      newState.validation = validation;
      newState.tableState = tableState;
      newState.debitOrCreditDialogState = debitOrCreditDialogState;
      newState.editTransactionDialogState = editTransactionDialogState;
      newState.waitigResponseForApprove = waitigResponseForApprove;
      newState.IsPlanFee = IsPlanFee;
      newState.IsBankPayment = IsBankPayment;
      newState.IsCardPaymentOrTransactionFee = IsCardPaymentOrTransactionFee;
      newState.filterTransactions = filterTransactions;

      return newState;
    });
  };

  updateFinance = () => {
    const { startDate, endDate } = this.state;
    const { companyService } = this.props;

    const updateFinance = () => companyService.finance.loadFinance(startDate, endDate);

    this.setState({ waitingResponse: { ...this.state.waitingResponse, transactions: true } }, updateFinance);
  };

  setItemsFilter = (filterKey, value) => {
    const { companyService: { finance: { setItemsFilter } } } = this.props;

    setItemsFilter(filterKey, value);
  };

  resetFilters = () => {
    const { companyService: { finance: { resetFilters } } } = this.props;

    resetFilters();
  };

  toggleDebitOrCreditDialog = (field) => {
    this.setState({
      debitOrCreditDialog: {
        show: Boolean(field),
        field: field || '',
      },
    });

    this.resetFilters();
  };

  handleChangeDate = (field, value) => {
    if (!this.state[field].isSame(value)) {
      this.setState({
        [field]: value,
      }, this.updateFinance);
    }

    this.resetFilters();
  };

  handleSetDate = (event, position) => {
    event.preventDefault();

    if (position === SINCELASTMONTH) {
      this.setState({
        startDate: getStartDateOfLastMonth(),
        endDate: getCurrentDate(),
      }, this.updateFinance);
    }
    if (position === ALLTIME) {
      this.setState({
        startDate: getSmartwayBirthDate(),
        endDate: getCurrentDate(),
      }, this.updateFinance);
    }

    this.resetFilters();
  };

  handleDeductionAvia = ({ EventDate, Period, Rate, StartDate }) => {
    const { accountId, companyId, companyService } = this.props;

    return companyService.finance.addDeductionAvia({ AccountId: accountId, CompanyId: companyId, EventDate, Period, Rate, StartDate });
  }

  handleShowDeductionAvia = (value) => {
    this.setState({
      showDeductionAviaDialog: value,
    });

    this.resetFilters();
  }

  handleAddDebitOrCredit = (date, amount, description, isCardFee) => {
    const { companyService, companyId } = this.props;
    const { debitOrCreditDialog: { field } } = this.state;

    const data = {
      Amount: amount,
      CreatedDate: formatDate(date, FULLTIME),
      Description: description,
      CompanyId: companyId,
      IsCardFee: isCardFee,
    };

    switch (field) {
      case CREDIT: {
        companyService.finance.addCredit(data)
          .then(this.toggleDebitOrCreditDialog)
          .then(this.updateFinance);

        break;
      }
      case CREDITBYCARD: {
        companyService.finance.addCardCredit(data)
          .then(this.toggleDebitOrCreditDialog)
          .then(this.updateFinance);

        break;
      }
      case DEBIT: {
        companyService.finance.addDebit(data)
          .then(this.toggleDebitOrCreditDialog)
          .then(this.updateFinance);

        break;
      }
    }
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading
        size='large'
      />
    </div>
  );

  renderHeader = () => (
    <div className={ styles.header }>
      { LABELS.ACCOUNT_TRSANSACTIONS }
    </div>
  );

  renderDateRange = (startDate, endDate, maxDate) => (
    <div className={ styles['date-range'] }>
      <span className={ styles['date-text'] }>
        { LABELS.DISPLAY_TRANSACTIONS }
      </span>
      <RangeDatePicker
        startDate={ startDate }
        endDate={ endDate }
        maxDate={ maxDate }
        onChange={ this.handleChangeDate }
        placeholderNameFrom={ ACCOUNTTEXT.FROM }
        placeholderNameTo={ ACCOUNTTEXT.TO }
      />
      <div className={ styles.position }>
        <span>(&nbsp;</span>
        <a
          className={ styles.link }
          onClick={ event => this.handleSetDate(event, SINCELASTMONTH) }
        >
          { LABELS.FROM_PREVIOUS_MONTH }
        </a>
        <span>,&emsp;</span>
        <a
          className={ styles.link }
          onClick={ event => this.handleSetDate(event, ALLTIME) }
        >
          { LABELS.ALL_TIME }
        </a>
        <span>&nbsp;)</span>
      </div>
    </div>
  );

  renderOnlyPlanFlee = () => {
    const { IsPlanFee } = this.state;

    return (
      <div
        className={ styles.checkbox_wrapper }
      >
        <Checkbox
          className={ styles.item_wrapper }
          value={ IsPlanFee }
          onChange={ () => this.setItemsFilter(FILTERS.IS_PLAN_FEE, !IsPlanFee) }
        />
        <div
          className={ styles.checkbox_text }
        >
          { LABELS.ONLY_PLAN_FEE }
        </div>
      </div>
    );
  }

  renderOnlyBankPayment = () => {
    const { IsBankPayment } = this.state;

    return (
      <div
        className={ styles.checkbox_wrapper }
      >
        <Checkbox
          className={ styles.item_wrapper }
          value={ IsBankPayment }
          onChange={ () => this.setItemsFilter(FILTERS.IS_BANK_PAYMENT, !IsBankPayment) }
        />
        <div
          className={ styles.checkbox_text }
        >
          { LABELS.ONLY_BANK_PAYMENT }
        </div>
      </div>
    );
  }

  renderOnlyCardPaymentOrTransactionFee = () => {
    const { IsCardPaymentOrTransactionFee } = this.state;

    return (
      <div
        className={ styles.checkbox_wrapper }
      >
        <Checkbox
          className={ styles.item_wrapper }
          value={ IsCardPaymentOrTransactionFee }
          onChange={ () => this.setItemsFilter(FILTERS.IS_CARD_PAYMENT_OR_TRANSACTION_FEE, !IsCardPaymentOrTransactionFee) }
        />
        <div
          className={ styles.checkbox_text }
        >
          { LABELS.ONLY_CARD_PAYMENT_OR_TRANSACTION_FEE }
        </div>
      </div>
    );
  }

  renderBtnDeductionAB = () => {
    const { AddFlightCertificateFee } = this.props.featureFlagsService.get();

    if (AddFlightCertificateFee) {
      return (
        <div className={ styles.action }>
          <Button
            label={ LABELS.ADD_DEDUCTION_AB.toUpperCase() }
            theme='flat'
            onClick={ () => this.handleShowDeductionAvia(true) }
          />
        </div>
      );
    }

    return null;
  }

  renderActions = () => (
    <div className={ styles.actions_wrapper } >
      <div className={ styles.actions }>
        <div className={ styles.checkbox_wrap }>
          { this.renderOnlyPlanFlee() }
          { this.renderOnlyBankPayment() }
          { this.renderOnlyCardPaymentOrTransactionFee() }
        </div>
        <div className={ styles.action_wrapper }>
          <div className={ styles.actions_card }>
            <div className={ styles.action }>
              <Button
                label={ LABELS.ADD_PLUS }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ () => this.toggleDebitOrCreditDialog(CREDIT) }
              />
            </div>
            <div className={ styles.action }>
              <Button
                label={ LABELS.ADD_MINUS }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ () => this.toggleDebitOrCreditDialog(DEBIT) }
              />
            </div>
          </div>
          <div className={ styles.actions_card }>
            <div className={ styles.action }>
              <Button
                label={ LABELS.PAY_CARD }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ () => this.toggleDebitOrCreditDialog(CREDITBYCARD) }
              />
            </div>
            { this.renderBtnDeductionAB() }
          </div>
        </div>
      </div>
    </div>
  );

  renderTable = ({
    loading,
    companyService,
    transactions,
    errorFlag,
    pagination,
    validation,
    tableState,
    editTransactionDialogState,
    featureFlagsService,
    filterTransactions,
    IsPlanFee,
    IsBankPayment,
    IsCardPaymentOrTransactionFee,
    accountId,
  }) => (
    loading ? this.renderLoading() : (
      <Table
        companyService={ companyService }
        updateFinance={ this.updateFinance }
        transactions={ transactions }
        pagination={ pagination }
        state={ tableState }
        validation={ validation }
        editTransactionDialogState={ editTransactionDialogState }
        featureFlagsService={ featureFlagsService }
        waitigResponseForApprove={ this.state.waitigResponseForApprove }
        errorFlag={ errorFlag }
        filterTransactions={ filterTransactions }
        IsPlanFee={ IsPlanFee }
        IsBankPayment={ IsBankPayment }
        IsCardPaymentOrTransactionFee={ IsCardPaymentOrTransactionFee }
        accountId={ accountId }
      />
    )
  );

  renderDeductionAviaDialog = () => {
    const { showDeductionAviaDialog } = this.state;
    const { companyId, accountService } = this.props;

    if (!showDeductionAviaDialog) {
      return null;
    }

    const { LastFlightCertificateFeeHistories } = accountService.get().account;

    return (
      <PlanFee
        companyId={ companyId }
        arrayRate={ RATE_LIST }
        lastPlanFeeHistories={ LastFlightCertificateFeeHistories }
        onAddPlanFee={ this.handleDeductionAvia }
        onClose={ () => this.handleShowDeductionAvia(false) }
        labelHeader={ LABELS.ADD_DEDUCTION_AB }
        placeholderLabel={ LABELS.SELECT_AMOUNT }
      />
    );
  };

  render() {
    const {
      companyService,
      featureFlagsService,
      accountId,
    } = this.props;
    const {
      startDate,
      endDate,
      maxDate,
      transactions,
      pagination,
      validation,
      tableState,
      errorFlag,
      debitOrCreditDialogState,
      editTransactionDialogState,
      debitOrCreditDialog,
      waitingResponse,
      filterTransactions,
      IsPlanFee,
      IsBankPayment,
      IsCardPaymentOrTransactionFee,
    } = this.state;

    const debitOrCreditDialogElem = debitOrCreditDialog.show ? (
      <DebitOrCreditDialog
        featureFlagsService={ featureFlagsService }
        companyService={ companyService }
        state={ debitOrCreditDialogState }
        validation={ validation.debitOrCreditDialog }
        onAddDebitOrCredit={ this.handleAddDebitOrCredit }
        /* onClose вызывается с объектом event, но нам нужен вызов */
        /* toggleDebitOrCreditDialog без параметров, поэтому в таком виде */
        onClose={ () => this.toggleDebitOrCreditDialog() }
        field={ debitOrCreditDialog.field }
      />
    ) : null;

    return (
      <div className={ styles.wrap }>
        { this.renderHeader() }
        { this.renderDateRange(startDate, endDate, maxDate) }
        { this.renderActions() }
        { this.renderTable({
          loading: waitingResponse.transactions,
          companyService,
          transactions,
          pagination,
          errorFlag,
          validation,
          tableState,
          editTransactionDialogState,
          featureFlagsService,
          filterTransactions,
          IsPlanFee,
          IsBankPayment,
          IsCardPaymentOrTransactionFee,
          accountId,
        }) }
        { debitOrCreditDialogElem }
        { this.renderDeductionAviaDialog() }
      </div>
    );
  }
}

export default Finance;
